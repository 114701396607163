var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HorLayout",
    [
      _c("Breadcrumbs", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(" ")
      ]),
      _c("SideRoute", {
        attrs: { slot: "nav", menus: _vm.menus },
        slot: "nav"
      }),
      _c("SideMenu", {
        attrs: { slot: "left", menus: _vm.menus },
        slot: "left"
      }),
      _c(
        "div",
        { attrs: { slot: "right" }, slot: "right" },
        [
          _c(
            "transition",
            { attrs: { name: "slide", mode: "out-in" } },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }