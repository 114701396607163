<template>
	<HorLayout>
		<Breadcrumbs slot="title">&nbsp;</Breadcrumbs>
		<SideRoute slot="nav" :menus="menus"></SideRoute>
		<SideMenu slot="left" :menus="menus"></SideMenu>
		<div slot="right">
			<transition name="slide" mode="out-in"><router-view></router-view></transition>
		</div>
	</HorLayout>
</template>

<script>
import HorLayout from '@/components/layout/HorLayout.vue';
import SideMenu from '@/components/SideMenu/index.vue';
import SideRoute from '@/components/SideRoute.vue';
// import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	name: 'Account',
	data() {
		return {
			menus: [
				{icon: '', text: 'Billing Information', path: `/billing/billing-info`},
				{ icon: '', text: 'Invoices', path: `/billing/invoices` },
				{ icon: '', text: 'Payment Methods', path: `/billing/paymentmethod` },
				{ icon: '', text: 'Credits', path: `/billing/credits` }
			]
		};
	},
	components: {
		HorLayout,
		SideMenu,
		SideRoute
		// Breadcrumbs
	},
	methods: {}
};
</script>

<style></style>
